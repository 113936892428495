import React from "react"
import styled from "@emotion/styled"
import { Section } from "../../Section"
import { useTheme } from "emotion-theming"
import Icon from "../../../Icon/Icon"

const QuoteHero = ({
  icon,
  heading,
  text,
  fullViewHeight,
  children,
  customWidth,
}) => {
  const theme = useTheme()

  return (
    <Section bgColor="#ffffff" fullViewHeight={fullViewHeight}>
      <QuoteHeroContent customWidth={customWidth}>
        <Icon
          name={icon}
          bgColor={theme.colors.CardContainer.iconBackground}
          iconColor={theme.colors.CardContainer.iconColor}
          iconSize="25"
        />

        <h1 dangerouslySetInnerHTML={{ __html: heading }}></h1>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
        {children}
      </QuoteHeroContent>
    </Section>
  )
}
export default QuoteHero

const QuoteHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h1,
  p {
    max-width: 305px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;

    span {
      display: block;
      color: #5558ea;
      margin-top: 10px;
    }
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    /* @media (max-width: 375px) {
      font-size: 13px;
      max-width: ${props => props.customWidth || "240px"};
    } */
  }
`
