import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import QuoteHero from "../components/PageComponents/QuotePage/QuoteHero/QuoteHero"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="evoli - Sidan kunde inte hittas" noIndex={true} />
      <QuoteHero icon="stop" heading="Sidan kunde inte hittas" text="">
        <Text>Länken du klickade på pekar på en sida som inte finns.</Text>
      </QuoteHero>
    </Layout>
  )
}

export default NotFoundPage

const Text = styled.div`
  margin-top: 4px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: center;
  color: #63638f;
  margin-bottom: 0;
  max-width: 200px;
`
